
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../superCode/firebase/firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where, updateDoc, Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";


// @mui 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// @mui material components
import Grid from "@mui/material/Grid";

import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

import { useLocation } from 'react-router-dom';
import { useRadioGroup } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Dashboard() {
  // modal
  const [open, setOpen] = React.useState(false);
  const [data, setRobotData] = useState([]);

  const handleOpen = (data) => {
    setOpen(true)
    setRobotData(data)
  }
  const handleClose = () => setOpen(false);

  const [values, setValues] = useState({
    name: '',
    password: '',
    email: '',
    referral_code: '',
    my_upline: '',
    userIpAddress: '',
    country: '',
    register_date: '',
    telegram_name: '',
    balance: '',
    deposits: [],
    withdrawals: [],
    usdt_address: '',
    currently_investing: '',
    has_deposited: '',
    referrals: [],
    robots: [],
    total_withdrawals: ''
  });

  const handleUserChange = (prop, value) => {
    // console.log("Setting value for key: " + prop + " = " + value)
    setValues({ ...values, [prop]: value });
    // console.log(prop + ": " + value)
  }//end of handleUserChange

  let navigate = useNavigate();

  // const [email, setEmail] = useState("");
  const [activeRobot, setShowActiveRobots] = useState(false);
  const [user, setUser] = useState({
    name: '',
    password: '',
    email: '',
    referral_code: '',
    my_upline: '',
    userIpAddress: '',
    country: '',
    register_date: '',
    telegram_name: '',
    balance: '',
    deposits: [],
    withdrawals: [],
    usdt_address: '',
    currently_investing: '',
    has_deposited: '',
    referrals: [],
    robots: [],
    total_withdrawals: ''

  })

  const [counter, setCounter] = useState(5)
  const [showClaimButtom, setShowClaimButton] = useState(false)
  const { sales, tasks } = reportsLineChartData;

  const handleLogOut = () => {
    console.log("logging out user..")
    localStorage.clear()

    handleUserChange('email', '')
    navigate("../authentication/sign-in")
  }

  // const location = useLocation()
  // const user = location.state.user
  // let userLoggedIn = false

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const loggedInUser = localStorage.getItem("userEmail")
    console.log("loggedInUser: " + loggedInUser)

    if (loggedInUser) {
      // get user data from database using his email
      getUserData(loggedInUser)
    } else {
      console.log("There is not user logged in, send user to sign in page")

      // navigate("../authentication/sign-in")
    }
    // Update the document title using the browser API
  }, []);


  const getUserData = async (email) => {
    const usersRef = collection(db, "users")

    //Create a query against the collection
    const q = query(usersRef, where("email", "==", email))
    const querySnapshot = await getDocs(q)

    let user = {}

    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      for (let key in doc.data()) {
        if (doc.data(key)) {
          // console.log(key)

          // handleUserChange(key, doc.data()[key])

        }
      }

      user.name = doc.data().name,
        user.email = doc.data().email,
        user.password = doc.data().password,
        user.referral_code = doc.data().referral_code,
        user.my_upline = doc.data().my_upline,
        user.userIpAddress = doc.data().userIpAddress,
        user.country = doc.data().country,
        user.register_date = doc.data().register_date,
        user.telegram_name = doc.data().telegram_name,
        user.balance = doc.data().balance,
        user.deposits = doc.data().deposits,
        user.withdrawals = doc.data().withdrawals,
        user.usdt_address = doc.data().usdt_address,
        user.currently_investing = doc.data().currently_investing,
        user.has_deposited = doc.data().has_deposited,
        user.referrals = doc.data().referrals,
        user.robots = doc.data().miners,
        user.total_withdrawals = doc.data().total_withdrawals

    })

    setUser(user)
    setShowActiveRobots(true)
    // localStorage.setItem('user', user)
  }//end of getUserData

  const renderEarningsFromRobots = (robots) => {
    // console.log("User Active Robots: " + JSON.stringify(user.robots[0].created_on))
    // current date
    const current = new Date();
    //date robot was bought

    return robots.map((robot, index) => {
      const robotDate = new Date(robot.created_on.seconds * 1000).toLocaleDateString("en-US")
      const robotNewDate = new Date(robotDate)
      // time the robot claim rewards
      const a = new Date(robot.last_time_mined.seconds * 1000).toLocaleDateString("en-US")
      const b = new Date(robot.last_time_mined.seconds * 1000).toLocaleTimeString("en-US")
      const lastClaimDate = a + " at " + b
      const lastClaim = new Date((robot.last_time_mined).toDate())

      let lastClaimInDays = Math.abs(current.getTime() - lastClaim.getTime())

      // let minutes = Math.floor((  lastClaimInDays / 1000) / 60)
      let minutes = (lastClaimInDays / (60 * 1000))
      minutes = 1440 - minutes

      let hours = minutes / 60
      let rHours = Math.floor(hours)
      let newMins = (hours - rHours) * 60;
      let newRMins = Math.round(newMins)
      if (minutes < 0) { minutes = 0 }
      //calculate the time difference of 2 dates
      let difference_in_time = current.getTime() - robotNewDate.getTime()

      //calculate the # of days between the 2 dates
      let difference_in_days = difference_in_time / (1000 * 3600 * 24)
      const earnings = (robot.earning_per_day * difference_in_days).toString().slice(0, 4)

      return (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Card sx={{ maxWidth: 400 }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={robot.img}
                  alt="playbox mining"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Starting Date: {new Date(robot.created_on.seconds * 1000).toLocaleDateString("en-US")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Validity = 90Days
                  </Typography>
                  {/* <Typography variant="h6" color="text.secondary">
                    Last claimed on: {lastClaimDate}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  {minutes == 0 ?
                    claimableButton(robot.earning_per_day, user.referral_code, user.balance, robot.created_on, robot.farming_time, index, robots, robot.img)
                    : unclaimableButton(rHours, newRMins, robot.earning_per_day)
                  }

                </CardActions>
              </Card>
            </MDBox>
          </Grid>
        </React.Fragment>
      )
    })
  } //end of renderEarningFromRobots



  const claimableButton = (earning_per_day, referral_code, balance, created_on, farming_time, index, robots, img) => {
    return (
      <MDButton onClick={() => claimRewards(earning_per_day, referral_code, balance, created_on, farming_time, index, robots, img)} variant="gradient" color="info" fullWidth >
        CLAIM ${earning_per_day}
      </MDButton>
    )
  }// end of claimableButton

  const unclaimableButton = (hours, minutes, earning_per_day) => {
    return (
      <MDButton disabled variant="gradient" color="info" fullWidth >
        ${earning_per_day} claimable in {hours}h {minutes}m
      </MDButton>
    )
  } //end of unclaimableButton

  const claimRewards = (earning_per_day, userReferralCode, balance, created_on, farming_time, index, robots, img) => {
    //refresh the page 
    // navigate("./")
    setShowClaimButton(false)
    addClaimedRewardsToBalance(userReferralCode, balance, earning_per_day, created_on, farming_time, index, robots, img)
  }//end of claimRewards

  const addClaimedRewardsToBalance = async (userReferralCode, balance, earning_per_day, created_on, farming_time, index, robots, img) => {
    console.log("*** UPDATING USER BALANCE ***")
    console.log("userReferralCode: " + userReferralCode)
    console.log("balance: " + balance)
    console.log("rewards: " + earning_per_day)
    console.log("Bot ID: " + index)

    let tempRobots = [...robots]

    let farmTime = farming_time
    console.log("farmTime: " + JSON.stringify(farmTime))
    let newFarmTime = [...farmTime, Timestamp.now(),]
    console.log("farmTime: " + JSON.stringify(farmTime))

    let tempMiner = {
      // created_on: new Date('September 12, 2022 12:00:01'),
      created_on: created_on,
      earning_per_day: earning_per_day,
      has_membership: false,
      last_time_mined: Timestamp.now(),
      robot_class: "standar",
      // farming_time: [ new Date('September 18, 2022 09:11:01') ]
      farming_time: newFarmTime,
      img: img
    }

    tempRobots[index] = tempMiner
    // console.log("TempRobots: " + JSON.stringify(tempRobots))
    // console.log("----------------- ")
    // console.log("----------------- ")
    // console.log("----------------- ")

    const newBalance = balance + earning_per_day
    const uplinerRef = doc(db, "users", userReferralCode.toString())
    try {
      await updateDoc(uplinerRef, {
        balance: newBalance,
        miners: tempRobots
      })
      window.location.reload();
    } catch (err) {
      alert(err)
    }

  } //addClaimedRewardsToBalance

  const claimedButton = () => {
    return (
      <React.Fragment>
        <MDButton disabled variant="gradient" color="info" fullWidth >
          Already claim today
        </MDButton>
      </React.Fragment>
    )
  }

  const renderEmptySpace = () => {
    // console.log(JSON.stringify(user))
    return (
      <React.Fragment>
        <Card sx={{ maxWidth: 400 }}>
          <CardMedia
            component="img"
            height="140"
            image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/robotMining.png"
            alt="playboxmining"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              You don't have any Active Robots. Please make a deposit to start earning.
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
      </React.Fragment>
    )
  } //end of renderEarningFromRobots

  const renderEarningsInModal = () => {
    return (
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography>
      </Box>
    )

  }//end of renderEarningsInModal

  return (
    <DashboardLayout >

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {renderEarningsInModal()}

      </Modal>

      <DashboardNavbar
        absolute={false}
        light={false}
        isMini={false}
        handleLogOut={handleLogOut}
      />
      <Card>
        <CardContent>
          <Typography variant="h6" color="text.secondary">
            Invitation Link: www.playboxmining.com/authentication/sign-up?refCode={localStorage.getItem("userRefCode")}
          </Typography>
        </CardContent>
      </Card>


      <Grid item xs={12} md={12} lg={12}>
        <MDBox py={3}>
          <Card sx={{ maxWidth: 1000 }} >
            <img
              // src="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/PlayBoxMiningBanner_copy_860x510.jpg"
              src="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/PlayBoxMiningBanner2.jpg"
              alt="car"
              width="100%"
              
            />
          </Card>
        </MDBox>
      </Grid>




      <MDBox py={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="paid"
                title="Available to Withdraw"
                count={"$" + user.balance}
                // count={"$" + user.balance.toFixed(2) }
                percentage={{
                  color: "success",
                  amount: "",
                  label: user.name + " | " + user.email,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="paid"
                title="Total Withdrawals"
                count={"$" + user.total_withdrawals}
                percentage={{
                  color: "success",
                  amount: "",
                  label: '',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="store"
                title="Robots"
                count={user.robots.length}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Referrals"
                count={user.referrals.length}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>


        <MDBox py={4}>
          <Grid container spacing={3}>
            {activeRobot ? renderEarningsFromRobots(user.robots) : renderEmptySpace()}
          </Grid>
        </MDBox>

      </MDBox>


      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
