
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from '../../superCode/firebase/firebase'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, arrayUnion } from 'firebase/firestore'

// @mui material components
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";


// @mui 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useLocation } from 'react-router-dom';
import { useRadioGroup } from '@mui/material';


function Dashboard() {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [refCode, setRefCode] = useState("");
    const [balance, setBalance] = useState(0);
    const [user, setUser] = useState()
    const [referralSize, setReferralSize] = useState(0)

    const [userHasFreeBot, setUserHasFreeBot] = useState(false)

    const { sales, tasks } = reportsLineChartData;

    const handleLogOut = () => {
        console.log("logging out user..")
        localStorage.clear()
        setEmail('')
        navigate("../authentication/sign-in")

    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")
        console.log("loggedInUser: " + loggedInUser)

        if (loggedInUser) {
            // get user data from database using his email
            console.log("We have a user logged in!!: " + loggedInUser)
            setEmail(loggedInUser)
            setRefCode(userRefCode)
            getUserBalance()

        } else {
            console.log("There is not user logged in, send user to sign in page")
            navigate("../authentication/sign-in")
        }
        // Update the document title using the browser API
    }, []);

    // Adds a new Robot to the user database
    const buyRobot = async (robotLevel) => {
        console.log("Hey from buyRobot()...")
        const userRefCode = localStorage.getItem("userRefCode")
        const userEmail = localStorage.getItem("userEmail")
        // const userBalance = localStorage.getItem("userBalance")
        console.log("Retrieving userRefCode from local Storage: " + userRefCode)
        console.log("Retrieving userEmail from local Storage: " + userEmail)

        // Get a referente to the user database
        const usersRef = collection(db, "users")

        //Create a query against the collection
        const q = query(usersRef, where("email", "==", userEmail))
        const querySnapshot = await getDocs(q)

        let userBalance = 0

        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            console.log("Balance for user email: " + userEmail + " => " + doc.data().balance)
            userBalance = doc.data().balance
        })

        let tempMiner = {}
        // let userBalance = 180

        if (robotLevel === "standard") {
            tempMiner = {
                price: 30,
                created_on: Timestamp.now(),
                earning_per_day: 1,
                has_membership: false,
                last_time_mined: Timestamp.now(),
                robot_class: "standard",
                farming_time: [],
                img: "https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-green.jpg"
            }
        }
        if (robotLevel === "bronze") {
            tempMiner = {
                price: 50,
                created_on: Timestamp.now(),
                earning_per_day: 1.40,
                has_membership: false,
                last_time_mined: Timestamp.now(),
                robot_class: "bronze",
                farming_time: [],
                img: "https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-orange.jpg"

            }
        }
        if (robotLevel === "silver") {
            tempMiner = {
                price: 100,
                created_on: Timestamp.now(),
                earning_per_day: 2.80,
                has_membership: false,
                last_time_mined: Timestamp.now(),
                robot_class: "silver",
                farming_time: [],
                img: "https://filedn.com/lrjmguE73G2b4rRojAEKg3j/robotMining.png"

            }
        }
        if (robotLevel === "gold") {
            tempMiner = {
                price: 300,
                created_on: Timestamp.now(),
                earning_per_day: 8.40,
                has_membership: false,
                last_time_mined: Timestamp.now(),
                robot_class: "gold",
                farming_time: [],
                img: "https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-gold.jpg"

            }
        }
        if (robotLevel === "platinum") {
            tempMiner = {
                price: 500,
                created_on: Timestamp.now(),
                earning_per_day: 13.90,
                has_membership: false,
                last_time_mined: Timestamp.now(),
                robot_class: "platinum",
                farming_time: [],
                img: "https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-black.jpg"

            }
        }

        // Add Robot details to the user
        if (userBalance >= tempMiner.price) {
            console.log("User balance: $" + userBalance + " is greater than Robot price: " + tempMiner.price)
            navigate("../dashboard")
            addRobotToUserDatabase(userBalance, tempMiner, userRefCode.toString())
        } else {
            console.log("User balance: $" + userBalance + " is not enough to buy Robot priced at: $" + tempMiner.price)
        }
    } //end of buyRobot

    const addRobotToUserDatabase = async (userBalance, tempMiner, userRefCode) => {
        const uplinerRef = doc(db, "users", userRefCode)
        try {
            await updateDoc(uplinerRef, {
                balance: userBalance - tempMiner.price,
                miners: arrayUnion(tempMiner)
            })
        } catch (err) {
            alert(err)
        }
    }// end of addRobotToUserDatabase

    const getUserBalance = async () => {
        console.log("Updating User Balance")
        const userEmail = localStorage.getItem("userEmail")
        // const userBalance = localStorage.getItem("userBalance")
        console.log("Retrieving userEmail from local Storage: " + userEmail)

        // Get a referente to the user database
        const usersRef = collection(db, "users")

        //Create a query against the collection
        const q = query(usersRef, where("email", "==", userEmail))
        const querySnapshot = await getDocs(q)

        let userBalance = 0
        let referrals = 0
        let robots = []

        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userBalance = doc.data().balance
            referrals = doc.data().referrals.length
            robots = doc.data().miners
        })

        console.log(JSON.stringify(robots))
        let userHasFreeRobot = robots.filter(bot => {
            if (bot.robot_class === "standard") {
                setUserHasFreeBot(true)
            }
        })

        console.log("userHasFreeRobot: " + userHasFreeRobot)


        setBalance(userBalance)
        setReferralSize(referrals)
    } //end of getUserBalance

    return (
        <DashboardLayout >
            <DashboardNavbar
                absolute={false}
                light={false}
                isMini={false}
                handleLogOut={handleLogOut}
            />
            <MDBox py={3}>

                <MDBox mt={4.5}>
                    <Typography gutterBottom variant="h5" component="div">
                        Balance: ${balance.toString().slice(0, 5)}
                    </Typography>
                    <Grid container spacing={3}>
                        {!userHasFreeBot ?
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-green.jpg"
                                            alt="playbox mining"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                STANDARD AI ROBOT
                                            </Typography>
                                            <Typography variant="body3" color="text.secondary">
                                                This Standard AI Robot can generate 1 USD for investors every day, and it can generate 90 USD in 90 Max Days.
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Validity = 90 Days
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <MDButton disabled={balance >= 30 ? false : true} onClick={email => buyRobot("standard")} variant="gradient" color="info" fullWidth >
                                                BUY $30
                                            </MDButton>
                                        </CardActions>
                                    </Card>
                                </MDBox>
                            </Grid>
                            : null
                        }


                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-orange.jpg"
                                        alt="playbox mining"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            BRONZE AI ROBOT
                                        </Typography>
                                        <Typography variant="body3" color="text.secondary">
                                            This Bronze AI Robot can generate 1.40 USD for investors every day, and it can generate 126 USD in 90  Max Days.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Validity = 90 Days
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <MDButton disabled={balance >= 50 ? false : true} onClick={email => buyRobot("bronze")} variant="gradient" color="info" fullWidth >
                                            BUY $50
                                        </MDButton>
                                    </CardActions>
                                </Card>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/robotMining.png"
                                        alt="playbox mining"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            SILVER AI ROBOT
                                        </Typography>
                                        <Typography variant="body3" color="text.secondary">
                                            This Silver AI Robot can generate 2.80 USD for investors every day, and it can generate 252 USD in 90  Max Days.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Validity = 90 Days
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <MDButton disabled={balance >= 100 ? false : true} onClick={email => buyRobot("silver")} variant="gradient" color="info" fullWidth >
                                            BUY $100
                                        </MDButton>
                                    </CardActions>
                                </Card>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-gold.jpg"
                                        alt="playbox mining"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            GOLD AI ROBOT
                                        </Typography>
                                        <Typography variant="body3" color="text.secondary">
                                            This GOLD AI Robot can generate 8.40 USD for investors every day, and it can generate 756 USD in 90  Max Days.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Validity = 90 Days
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <MDButton disabled={balance >= 300 ? false : true} onClick={email => buyRobot("gold")} variant="gradient" color="info" fullWidth >
                                            BUY $300
                                        </MDButton>
                                    </CardActions>
                                </Card>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image="https://filedn.com/lrjmguE73G2b4rRojAEKg3j/mining-black.jpg"
                                        alt="playbox mining"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            PLATINUM AI ROBOT
                                        </Typography>
                                        <Typography variant="body3" color="text.secondary">
                                            This PLATINUM AI Robot can generate 13.90 USD for investors every day, and it can generate 1,251 USD in 90  Max Days.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Validity = 90 Days
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <MDButton disabled={balance >= 500 ? false : true} onClick={email => buyRobot("platinum")} variant="gradient" color="info" fullWidth >
                                            BUY $500
                                        </MDButton>
                                    </CardActions>
                                </Card>
                            </MDBox>
                        </Grid>





                    </Grid>
                </MDBox>

            </MDBox>


            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Dashboard;
