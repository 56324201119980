import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA4TGcEDoZeZSoWeLAzTjabe81-dAdMkRU",
    authDomain: "playboxmining.firebaseapp.com",
    projectId: "playboxmining",
    storageBucket: "playboxmining.appspot.com",
    messagingSenderId: "1003586455258",
    appId: "1:1003586455258:web:6d971e6453b8b807a247e3",
    measurementId: "G-216789R6CY"
  };

  // Initialize Firebase and Firestore
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  // export const auth = getAuth()
  export {db, auth}