
import * as React from 'react';
import { useState, useEffect } from "react";


import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from '../../superCode/firebase/firebase'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, arrayUnion } from 'firebase/firestore'

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import DepositForm from "./DepositForm";
import Transactions from "layouts/billing/components/Transactions";

// Images
import usdtLogo from "assets/images/logos/usdt.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

const card = (
    <React.Fragment>
        <CardContent>
            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                Deposit Center
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.secondary">
                * Deposit ONLY USDT on the TRON Network(TRC-20) otherwise your deposit will be lost
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.secondary">
                * Minimum deposit amount is $20 USDT
            </Typography>
            <Typography sx={{ mb: 5 }} color="text.secondary">
                * Deposit confirmation may take up to 12 hours (usually it takes only 5-10 minutes for our processor to pick it up)
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.primary">
                Wallet: TBSiG1oM3LiqBc5phRjVZ2YEpSTe3tfnKw
            </Typography>
        </CardContent>
    </React.Fragment>
);

function Deposits() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    let navigate = useNavigate();

    const [wallet, setWallet] = useState("TQ2wSBgcUMMPvaJbRKwJwiDey2dBWpyyQ7")

    const [values, setValues] = useState({
        amount: '',
        txId: '',
        sender_address: '',
    });

    const createDeposit = async () => {
        console.log("*** Creatig a Deposit: ***")
        console.log("Amount: " + values.amount)
        console.log("Transaction ID: " + values.txId)
        console.log("From Usdt Address: " + values.sender_address)

        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")

        let newDeposit = {
            amount: values.amount,
            txId: values.txId,
            sender_address: values.sender_address,
            created_on: Timestamp.now(),
            status: "pending",
            description: "We are processing your deposit",
            deposit_wallet: wallet
        }

        //get user reference
        const uplinerRef = doc(db, "users", userRefCode)
        try {
            await updateDoc(uplinerRef, {
                deposits: arrayUnion(newDeposit)
            })
            getUserData(loggedInUser)
            window.location.reload();
        } catch (err) {
            alert(err)
        }
    }//end of createDeposit



    const [transactions, setTransactions] = useState([])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.log(prop + ": " + event.target.value)
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")
        console.log("userref: " + userRefCode)

        let randonNumber = Math.floor(Math.random() * 5 )

        let wallets = [
            "TQ2wSBgcUMMPvaJbRKwJwiDey2dBWpyyQ7",
            "TL4D5C81LLQJguU1d7rnBbgsWmYnkH1eKf",
            "TEYfxEfLfVzAZRuPuNKyu3kXRnopZjZpYn",
            "TAKoH6CGtnSdS8xrWu9YgnjtRB2fbBKaNK",
            "TAnzFkCrSZ8TPhR2TD92cZaJDxxLfYZwZB"
        ]

        let tempWallet = wallets[randonNumber]
        setWallet(tempWallet)

        if (loggedInUser) {
            // get user data from database using his email
            getUserData(loggedInUser)
            // setEmail(loggedInUser)
            // handleUserChange("email", loggedInUser)
        } else {
            console.log("There is not user logged in, send user to sign in page")
        }
        // Update the document title using the browser API
    }, []);

    const getUserData = async (email) => {
        const usersRef = collection(db, "users")

        //Create a query against the collection
        const q = query(usersRef, where("email", "==", email))
        const querySnapshot = await getDocs(q)

        let user = []

        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            console.log("getting info for user email: " + doc.data().deposits)
            user = doc.data().deposits.reverse()
        })

        setTransactions(user)
        console.log(JSON.stringify(user))

    }//end of getUserData


    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} xl={12}>
                                    <Box mb={3} sx={{ minWidth: 275 }}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                    Deposit Center
                                                </Typography>
                                                <Typography sx={{ mb: 0.2 }} color="text.secondary">
                                                    * Deposit ONLY USDT on the TRON Network(TRC-20) otherwise your deposit will be lost
                                                </Typography>
                                                <Typography sx={{ mb: 0.2 }} color="text.secondary">
                                                    * Minimum deposit amount is $20 USDT
                                                </Typography>
                                                <Typography sx={{ mb: 5 }} color="text.secondary">
                                                    * Deposit confirmation may take up to 12 hours (usually it takes only 5-10 minutes for our processor to pick it up)
                                                </Typography>
                                                <Typography sx={{ mb: 0.2 }} color="text.primary">
                                                    Wallet: {wallet}
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </Box>
                                    <Grid item xs={12}>
                                        <Card py={4} id="delete-account">
                                            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                                <MDTypography variant="h6" fontWeight="medium">
                                                    Make a deposit
                                                </MDTypography>

                                            </MDBox>
                                            <MDBox p={2}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <MDBox
                                                            borderRadius="lg"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            p={3}
                                                            sx={{
                                                                border: ({ borders: { borderWidth, borderColor } }) =>
                                                                    `${borderWidth[1]} solid ${borderColor}`,
                                                            }}
                                                        >
                                                            <MDBox component="img" src={usdtLogo} alt="master card" width="10%" mr={2} />
                                                            <MDBox width="80%" mb={2}>
                                                                <MDInput onChange={handleChange('amount')} type="text" label="Amount" fullWidth />
                                                            </MDBox>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MDBox
                                                            borderRadius="lg"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            p={3}
                                                            sx={{
                                                                border: ({ borders: { borderWidth, borderColor } }) =>
                                                                    `${borderWidth[1]} solid ${borderColor}`,
                                                            }}
                                                        >
                                                            <MDBox width="100%" mb={2}>
                                                                <MDInput onChange={handleChange('txId')} type="text" label="Transaction ID" fullWidth />
                                                            </MDBox>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MDBox
                                                            borderRadius="lg"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            p={3}
                                                            sx={{
                                                                border: ({ borders: { borderWidth, borderColor } }) =>
                                                                    `${borderWidth[1]} solid ${borderColor}`,
                                                            }}
                                                        >
                                                            <MDBox width="100%" mb={2}>
                                                                <MDInput onChange={handleChange('sender_address')} type="text" label="Address which sent the USDT" fullWidth />
                                                            </MDBox>
                                                        </MDBox>
                                                        <MDBox py={3}>
                                                            <MDButton onClick={createDeposit} variant="gradient" color="info">
                                                                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                                                &nbsp; Submit
                                                            </MDButton>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Card>




                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>
                            <Transactions
                                transactions={transactions}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Deposits;
