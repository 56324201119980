

import { useState } from "react";
import { db, auth } from "../../../superCode/firebase/firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

// react-router-dom components
import { Link, useSearchParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg4.jpeg";

const Basic = (props) => {
  let navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [values, setValues] = useState({
    name: '',
    password: '',
    email: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(prop + ": " + event.target.value)
  };

  const signInUser = () => {
    const auth = getAuth();

    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
        // console.log("User has been signed in: " + JSON.stringify(user.accessToken))
        getUserData(values.email)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }// end of signUser

  const getUserData = async userEmail => {
    const usersRef = collection(db, "users")

    //Create a query against the collection
    const q = query(usersRef, where("email", "==", userEmail))
    const querySnapshot = await getDocs(q)

    let user = {

    }

    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      console.log("Email of found user: " + doc.data().email)

      user.name = doc.data().name,
        user.email = doc.data().email,
        user.password = doc.data().password,
        user.referral_code = doc.data().referral_code,
        user.my_upline = doc.data().my_upline,
        user.userIpAddress = doc.data().userIpAddress,
        user.country = doc.data().country,
        user.register_date = doc.data().register_date,
        user.telegram_name = doc.data().telegram_name,
        user.balance = doc.data().balance,
        user.deposits = doc.data().deposits,
        user.withdrawals = doc.data().withdrawals,
        user.usdt_address = doc.data().usdt_address,
        user.currently_investing = doc.data().currently_investing,
        user.has_deposited = doc.data().has_deposited,
        user.referrals = doc.data().referrals,
        user.miners = doc.data().miners

    })

    localStorage.setItem('userEmail', user.email)
    localStorage.setItem('userRefCode', user.referral_code)
    localStorage.setItem('userBalance', user.balance)
    console.log("Saving to localStorage email and referral_code: " + user.email + " " + user.referral_code)
    navigate("../dashboard", { state: { user } })
  }//end of getUserData

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange={handleChange('email')} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={handleChange('password')} type="password" label="Password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={() => signInUser()} variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>

            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MDTypography>
              </MDTypography>
            </MDBox> */}

          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default Basic;
