/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import React from "react";

function Transactions(props) {

  const showTransactions = (transactions) => {
    console.log(JSON.stringify(transactions))

    return transactions.map((transaction, index) => {
      return (
        <React.Fragment key={index}>


          <MDBox pt={0.3} pb={2} px={2}>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <Transaction
                color={transaction.status === "success" ? "success" : "dark"}
                icon="money"
                name={transaction.description.toUpperCase()}
                description={transaction.created_on.toDate().toString().slice(0, 34)}
                value={transaction.status.toUpperCase() + " + $" + transaction.amount}
              />
            </MDBox>
          </MDBox>
        </React.Fragment>
      )
    })



  }// end of showTransactions

  const showEmptyTransactions = () => {
    return (
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          There no transactions yet 
        </MDTypography>
      </MDBox>
    )
  }



  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Your Transaction&apos;s
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              status
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            Status
          </MDTypography>
        </MDBox>
      </MDBox>
      {props.transactions.length === 0 ? showEmptyTransactions() : showTransactions(props.transactions)}

    </Card>
  );
}

export default Transactions;
