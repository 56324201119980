
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../superCode/firebase/firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where, updateDoc, Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";


// Overview page components
import Header from "layouts/profile/components/Header";


function Overview() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    referral_code: "",
    userIpAddress: "",
    country: "",
    register_date: Timestamp.now(),
    referrals: "",
    robots: ""
  })

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const loggedInUser = localStorage.getItem("userEmail")
    console.log("loggedInUser: " + loggedInUser)

    if (loggedInUser) {
      // get user data from database using his email
      getUserData(loggedInUser)
    } else {
      console.log("There is not user logged in, send user to sign in page")

      // navigate("../authentication/sign-in")
    }
    // Update the document title using the browser API
  }, []);


  const getUserData = async (email) => {
    const usersRef = collection(db, "users")

    //Create a query against the collection
    const q = query(usersRef, where("email", "==", email))
    const querySnapshot = await getDocs(q)

    let user = {}

    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      user.name = doc.data().name,
        user.email = doc.data().email,
        user.referral_code = doc.data().referral_code,
        user.userIpAddress = doc.data().userIpAddress,
        user.country = doc.data().country,
        user.register_date = doc.data().register_date,
        user.referrals = doc.data().referrals,
        user.robots = doc.data().miners

    })
    setUser(user)

  }//end of getUserData


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={1} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description=""
                info={{
                  fullName: user.name,
                  location: user.country,
                  IPAddress: user.userIpAddress,
                  Robots: user.robots.length,
                  Referrals: user.referrals.length,
                  Registered: user.register_date.toDate().toString().slice(0,15),
                  // mobile: "(44) 123 1234 123",
                  email: user.email,

                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />

              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

          </Grid>
        </MDBox>

        <MDBox p={2}>

        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
