import axios from 'axios'
import React from 'react';
import { createUser } from './CreateUser';
import { db, auth } from './firebase/firebase'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, arrayUnion, } from 'firebase/firestore'
import { SignalCellularNullOutlined } from '@mui/icons-material';



const checkIfReferralCodeExists = async (name, email, password, referral_code, navigate) => {
    const usersRef = collection(db, "users")
    let myReferral = parseInt(referral_code)

    // Create a query against the collection
    const q = query(usersRef, where("referral_code", "==", myReferral))
    const querySnapshot = await getDocs(q);
    let isEmpty = true

    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.data())
        isEmpty = false
        // setUplinerId(doc.data().referral_code)
    })

    if (!isEmpty) {
        console.log("Local Referral code matches DB referrral code ")
        registerWithEmailAndPassword(name, email, password, referral_code, navigate)
    } else {
        console.log("Local code does not match ANY referral code in DB")
    }

} //checkIfReferralCodeExists

const registerWithEmailAndPassword = async (name, email, password, referral_code, navigate) => {
    console.log("email: " + email)

    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        console.log("Newly generated Auth Id: " + user.uid)
        // setAuthId(user.uid)

        // navigate("../authentication/sign-in")

        //create a referral code and add it to its own database 
        generateUniqueReferralCode(name, email, password, referral_code, navigate)

    } catch (err) {
        // console.error(err);
        // console.log(err.message);
        if(err.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
            alert("Password should be at least 6 chatanters long")
        }
        if(err.message === "Firebase: Error (auth/invalid-email).") {
            alert("Email is not valid. Please check and try again")

        }
        if(err.message === "Firebase: Error (auth/email-already-in-use).") {
            alert("Email has already been used. Please try another email.")
        }
    }

};

const generateUniqueReferralCode = async (name, email, password, uplinerId, navigate) => {
    console.log("+++ Generating user unique referral code...")
    const myOwnReferralCode = Math.floor(Math.random() * (1000000 - 100000)) + 100000;

    if (uplinerId === myOwnReferralCode) {
        // code exists in array
        console.log("+++ that code already exist, generating another one...")
        generateUniqueReferralCode(name, email, password, uplinerId, navigate)
    } else {
        console.log("setting local code: " + myOwnReferralCode)
        // setReferralCode(myOwnReferralCode)
        createUserInDb(name, email, password, myOwnReferralCode, uplinerId, navigate)
    }

}//end of generateUniqueReferralCode

const createUserInDb = async (name, email, password, myOwnReferralCode, uplinerId, navigate) => {
    console.log("^^^sending data to createUser(userDataO)")

    const res = await axios.get('https://geolocation-db.com/json/')


    const userData = {
        // authId: authId,
        name: name,
        email: email,
        password: password,
        userIpAddress: res.data.IPv4,
        userCountry: res.data.country_name,
        authProvider: "local",
        referral_code: myOwnReferralCode,
        my_upline: uplinerId, // I need to get code from URL or user needs to paste it
        navigate: navigate
    }
    // adds a new user once he is signed in
    createUser(userData)

}// end of createUserInDb

const getUserIpAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    let user = {
        userIp: res.data.IPv4,
        userCountry: res.data.country_name
    }
    return user
}// end of getUserIpAddress


export function RegisterNewUser(name, email, password, referral_code, navigate) {

    checkIfReferralCodeExists(name, email, password, referral_code, navigate)
    // get User IP Address
    // getUserIpAddress()

}