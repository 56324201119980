
import { useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from '../../superCode/firebase/firebase'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, arrayUnion } from 'firebase/firestore'

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Images
import usdtLogo from "assets/images/logos/usdt.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function WithdralForm() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const createDeposit = async () => {
        console.log("*** Creatig a Deposit: ***")
        console.log("Amount: " + values.amount)
        console.log("Transaction ID: " + values.txId)
        console.log("From Usdt Address: " + values.sender_address)

        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")

        let newDeposit = {
            amount: values.amount,
            txId: values.txId,
            sender_address: values.sender_address,
            created_on: Timestamp.now(),
            status: "pending",
            description: "We are processing your deposit"
        }

        //get user reference
        const uplinerRef = doc(db, "users", userRefCode)
        try {
            await updateDoc(uplinerRef, {
                withdrawals: arrayUnion(newDeposit)
            })
        } catch (err) {
            alert(err)
        }
    }

    const [values, setValues] = useState({
        amount: '',
        txId: '',
        sender_address: '',
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.log(prop + ": " + event.target.value)
    };

    return (
        <Card id="delete-account">
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                    Make a deposit
                </MDTypography>
                <MDButton onClick={createDeposit} variant="gradient" color="info">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Submit
                </MDButton>
            </MDBox>
            <MDBox p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDBox
                            borderRadius="lg"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                            sx={{
                                border: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                            }}
                        >
                            <MDBox component="img" src={usdtLogo} alt="master card" width="10%" mr={2} />
                            <MDBox width="80%" mb={2}>
                                <MDInput onChange={handleChange('amount')} type="text" label="Amount" fullWidth />
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox
                            borderRadius="lg"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                            sx={{
                                border: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                            }}
                        >
                            <MDBox width="100%" mb={2}>
                                <MDInput onChange={handleChange('txId')} type="text" label="Transaction ID" fullWidth />
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox
                            borderRadius="lg"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                            sx={{
                                border: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                            }}
                        >
                            <MDBox width="100%" mb={2}>
                                <MDInput onChange={handleChange('sender_address')} type="text" label="Address which sent the USDT" fullWidth />
                            </MDBox>
                        </MDBox>
                    </Grid>




                </Grid>
            </MDBox>
        </Card>
    );
}

export default WithdralForm;
