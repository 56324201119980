
import * as React from 'react';
import { useState, useEffect } from "react";


import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, auth } from '../../superCode/firebase/firebase'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, arrayUnion, increment } from 'firebase/firestore'

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import WithdrawalForm from "./WithdrawalForm";
import Transactions from "layouts/billing/components/Transactions";

// Images
import usdtLogo from "assets/images/logos/usdt.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

const card = (
    <React.Fragment>
        <CardContent>
            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                Withdrawal Center
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.secondary">
                * Withdrawal ONLY on the Tron Network(USDT TRC-20)
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.secondary">
                * Withdrawal Minimum: $35
            </Typography>
            <Typography sx={{ mb: 0.2 }} color="text.secondary">
                * Withdrawal confirmation may take up to 12 hours (usually it takes only 2-4 hours)
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
                * %5 Withdrawal fee
            </Typography>
        </CardContent>
    </React.Fragment>
);

function Withdrawalss() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    let navigate = useNavigate();

    const [balance, setBalance] = useState(0)
    const [values, setValues] = useState({
        amount: '',
        txId: '',
        sender_address: '',
    });

    const createWithdrawal = async () => {
        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")

        let newDeposit = {
            amount: values.amount,
            txId: '',
            sender_address: values.sender_address,
            created_on: Timestamp.now(),
            status: "pending",
            description: "We are processing your withdrawal"
        }

        if (values.amount >= 35) {
            if (balance > values.amount) {
                //get user reference
                const uplinerRef = doc(db, "users", userRefCode)
                try {
                    await updateDoc(uplinerRef, {
                        withdrawals: arrayUnion(newDeposit),
                        balance: balance - values.amount,
                        total_withdrawals: increment(values.amount)
                    })
                    //clear state
                    setValues({ amount: '', sender_address: '' })
                    //refresh data
                    getUserData(loggedInUser)
                    window.location.reload();
                } catch (err) {
                    alert(err)
                }
            } else {
                alert("You are trying to withdraw $" + values.amount + " which is more than your available balance $" + balance.toString().slice(0, 5))
            }

        } else {
            alert("Minimum withdrawal is: $35")
        }

    }//end of createWithdrawal

    const [transactions, setTransactions] = useState([])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const loggedInUser = localStorage.getItem("userEmail")
        const userRefCode = localStorage.getItem("userRefCode")
        if (loggedInUser) {
            // get user data from database using his email
            getUserData(loggedInUser)
            //clear state
            setValues({ amount: '', sender_address: '' })
            // setEmail(loggedInUser)
            // handleUserChange("email", loggedInUser)
        } else {
            console.log("There is not user logged in, send user to sign in page")
        }
        // Update the document title using the browser API
    }, []);

    const getUserData = async (email) => {
        const usersRef = collection(db, "users")

        //Create a query against the collection
        const q = query(usersRef, where("email", "==", email))
        const querySnapshot = await getDocs(q)

        let user = []
        let balance = 0

        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            console.log("getting info for user email: " + doc.data().deposits)
            user = doc.data().withdrawals.reverse()
            balance = doc.data().balance
        })

        setBalance(balance)
        setTransactions(user)

    }//end of getUserData


    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} xl={12}>
                                    <Box sx={{ minWidth: 275 }}>
                                        <Card variant="outlined">{card}</Card>
                                    </Box>
                                    <Grid pt={6} item xs={12}>
                                        <Card id="delete-account">
                                            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                                <MDTypography variant="h6" fontWeight="medium">
                                                    Request a Withdrawal
                                                </MDTypography>
                                                <MDTypography variant="h5" fontWeight="medium">
                                                    Balance: ${balance.toString().slice(0, 5)}
                                                </MDTypography>
                                                

                                            </MDBox>
                                            <MDBox p={2}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <MDBox
                                                            borderRadius="lg"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            p={3}
                                                            sx={{
                                                                border: ({ borders: { borderWidth, borderColor } }) =>
                                                                    `${borderWidth[1]} solid ${borderColor}`,
                                                            }}
                                                        >
                                                            <MDBox component="img" src={usdtLogo} alt="master card" width="10%" mr={2} />
                                                            <MDBox width="80%" mb={2}>
                                                                <MDInput onChange={handleChange('amount')} type="text" label="Amount" fullWidth />
                                                            </MDBox>
                                                        </MDBox>
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <MDBox
                                                            borderRadius="lg"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            p={3}
                                                            sx={{
                                                                border: ({ borders: { borderWidth, borderColor } }) =>
                                                                    `${borderWidth[1]} solid ${borderColor}`,
                                                            }}
                                                        >
                                                            <MDBox width="100%" mb={2}>
                                                                <MDInput onChange={handleChange('sender_address')} type="text" label="Receiver Address" fullWidth />
                                                            </MDBox>
                                                        </MDBox>
                                                        <MDBox py={3}>
                                                            <MDButton onClick={createWithdrawal} variant="gradient" color="info">
                                                                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                                                &nbsp; Submit
                                                            </MDButton>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>
                            <Transactions
                                transactions={transactions}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Withdrawalss;
