
import { db, auth } from "./firebase/firebase"
import { collection, addDoc, setDoc, Timestamp, updateDoc, arrayUnion, doc, getDoc } from 'firebase/firestore'
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import { useRef } from "react";

const createUser = async props => {
    console.log("props.referral_code: " + props.referral_code)

    await setDoc(doc(db, 'users', props.referral_code.toString()), {
        name: props.name,
        email: props.email,
        password: props.password,
        referral_code: props.referral_code,
        my_upline: props.my_upline,

        userIpAddress: props.userIpAddress,
        country: props.userCountry,

        register_date: Timestamp.now(),
        telegram_name: '',
        balance: 30,
        deposits: [],
        withdrawals: [],
        usdt_address: '',
        currently_investing: 0,
        has_deposited: false,
        total_withdrawals: 0,

        referrals: [],
        miners: []

    })

    addNewUserCodeToReferralCodesDb(props.my_upline.toString(), parseInt(props.referral_code), props.navigate)


}// end of createUser

const addNewUserCodeToReferralCodesDb = async (my_upline, referral_code, navigate) => {
    const uplinerRef = doc(db, "users", my_upline)
    try {
        await updateDoc(uplinerRef, {
            referrals: arrayUnion(referral_code)
        })

        navigate("../authentication/sign-in")
    } catch (err) {
        alert(err)
    }
}// end of addNewUserCodeToReferralCodeDb











const createUserrrrr = async props => {
    console.log("typeof props.uplinerDatabaseId: " + typeof props.uplinerDatabaseId)
    console.log("props.referral_code: " + props.referral_code)

    try {
        const userRef = await setDoc(doc(db, 'users', props.referral_code.toString()), {
            // const userRef = await addDoc(collection(db, 'users'), {
            authId: props.authId,
            name: props.name,
            // lastName: props.lastName,
            password: props.password,
            email: props.email,
            // userIpAddress: props.userIpAddress,
            // country: props.country,
            // register_date: Timestamp.now(),
            // telegram_name: '',
            // balance: 5,
            // deposits: [],
            // withdrawals: [],
            // usdt_address: '',
            // currently_investing: 0,
            // has_deposited: false,
            my_upline: props.my_upline, // 6 digit code from form
            uplinerDatabaseId: props.uplinerDatabaseId,
            referral_code: props.referral_code,
            referrals: [],
            bonusFromReferrals,
            // miners: [],
            // available_balance: 0,
        })

        // add its own ID to the newly creted userInfo
        console.log("User created with ID: " + userRef.id)
        const newUserRef = doc(db, "users", userRef.id)
        await updateDoc(newUserRef, {
            myDbId: userRef.id
        })

        addNewUserCodeToReferralCodesDb(userRef.id, props.referral_code, props.uplinerDatabaseId)
        // onClose()
    } catch (err) {
        alert(err + "could not create user in db")
    }

    // console.log(props.lastName)
}






export { createUser };
