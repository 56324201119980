import React, { useState, useEffect, useMemo, Fragment } from "react";

import { db, auth } from "../../superCode/firebase/firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where, updateDoc, Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/referrals/authorsTableData";

// Images
import team1 from "assets/images/team-1.jpg";
import profile from "assets/images/profile.png";

function Referrals() {
  // const { columns, rows } = authorsTableData();
  const [referrals, setReferrals] = useState([])

  const [columns, setColumns] = useState([
    { Header: "NAME", accessor: "author", width: "45%", align: "left" },
    { Header: "REFERRAL CODE", accessor: "function", align: "left" },
    { Header: "HAS DEPOSITED", accessor: "status", align: "center" },
    { Header: "REGISTERED", accessor: "employed", align: "center" },
    { Header: "BALANCE", accessor: "action", align: "center" },
  ])

  const [rows, setRows] = useState([])


  useEffect(() => {
    getUser()

  }, []) //end of useEffent

  const getUser = async () => {
    const email = localStorage.getItem("userEmail")
    const refCode = localStorage.getItem("userRefCode")

    const usersRef = collection(db, "users")

    //Create a query against the collection
    const q = query(usersRef, where("email", "==", email))
    const querySnapshot = await getDocs(q)

    let user = {}
    let referrals = []

    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      // console.log("getting info for user email: " + doc.data().email)
      console.log("referrals: " + doc.data().referrals)
      referrals = doc.data().referrals
    })

    getReferrals(refCode)

  }//end of getUser

  const getReferrals = async (userRefCode) => {
    // Create a reference to the users collection
    const usersRef = collection(db, "users");

    // Filter users ONLY if they were referred by "this" user
    // Create a query against the collection.
    const q = query(usersRef, where("my_upline", "==", userRefCode.toString()));

    const querySnapshot = await getDocs(q);
    let referrals = []

    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data())
      referrals = [ ...referrals, doc.data() ]
    })

    console.log("referrals.length: " + referrals.length)

    setReferrals(referrals)
    generateRows(referrals)

  }// getReferrals

  const generateColumns = () => {
    let columns = [
      { Header: "NAME", accessor: "author", width: "45%", align: "left" },
      { Header: "REFERRAL CODE", accessor: "function", align: "left" },
      { Header: "HAS DEPOSITED", accessor: "status", align: "center" },
      { Header: "REGISTERED", accessor: "employed", align: "center" },
      { Header: "BALANCE", accessor: "action", align: "center" },
    ]

    setColumns(columns)
  }//end of generateColumns

  const generateRows = (referrals) => {
    let tempReferrals = referrals.map((referral, index) => {
      return {
        author: <Author image={profile} name={referral.name} email={referral.email} />,
        function: <Job title={referral.referral_code} description={referral.country} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={referral.has_deposited ? "YES" : "NO"} color={referral.has_deposited ? "success" : "dark"} variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {referral.register_date.toDate().toString().slice(0,31)}
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ${referral.balance}
          </MDTypography>
        ),
      }
    })

    setRows(tempReferrals)
  } // end of generateRows

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  My Referrals
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>

                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>



        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Referrals;
